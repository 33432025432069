<template>
  <div>
    <b-row>
      <b-col sm="12" lg="2"></b-col>
      <b-col sm="12" lg="8"></b-col>
      <b-col sm="12" lg="2"></b-col>
    </b-row>
    <b-row style="margin-bottom: 20px; border-bottom: solid 1px whitesmoke;">
      <b-col sm="12" md="2" lg="2"> </b-col>
      <b-col sm="12" md="4" lg="4">
        <i class="fa fa-desktop"></i>
        {{ StoreLangTranslation.data['product_details'][StoreLang] }}
      </b-col>
      <b-col sm="12" md="4" lg="4" :style="StoreDevice.isMobile ? '' : 'text-align: right;'"></b-col>
      <b-col sm="12" md="2" lg="2"></b-col>
    </b-row>
    <b-row>
      <b-col sm="12" lg="2"></b-col>
      <b-col sm="12" lg="8">
          <template v-if="d_loading">
            <b-spinner variant="primary" label="Spinning"></b-spinner>
          </template>
          <template v-else>
            <b-row style="font-size: 16px;" v-if="d_bundleData">
              <b-col sm="12" lg="8">
                <b-row>
                  <b-col sm="12" md="12" lg="6">
                    <b-row style="text-align: left; border-radius: 10px 10px; background-color: #adfcbd; margin: 3px; padding: 3px; border-radius: 30px 0px 30px 0px;">
                      <b-col cols="12">
                        <strong> {{ d_bundleData.data['0']['10']['lang'][StoreLang].toLocaleUpperCase() }}</strong>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="12" lg="12">
                    <b-row style="margin: 3px; min-height: 50px; text-align: left; background-color: #f7e48f; padding: 10px; border-radius: 10px; box-shadow: -2px -2px #d4a803; font-weight: 900;">
                      <b-col cols="12">
                        <strong> {{ d_bundleData.data['0']['1']['lang'][StoreLang].toLocaleUpperCase() }}</strong>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <template v-if="d_bundleProductList.length > 0">
                  <b-row style="text-align: left; border-radius: 10px 10px; background-color: rgb(0 255 206); margin: 3px; padding: 3px;">
                    <b-col cols="12">
                      <i class="fa fa-angle-right"></i>
                      <strong> {{ StoreLangTranslation.data['product_list'][StoreLang] }}</strong>
                    </b-col>
                  </b-row>
                    <template v-for="(product_item, product_item_index) in d_bundleProductList" v-if="f_showCheckProduct(product_item.product)">
                      <b-row style="margin: 5px; padding: 5px;">
                        <b-col sm="12" lg="9">
                          <i class="fa fa-angle-double-right" style="color: rgb(0 116 94);"></i>
<!--                           <template v-if="d_user && d_user.auth.indexOf('admin') !== -1">
                              <i @click="f_openProductIngredients(product_item.product)" style="border-radius: 10px; padding-right: 5px; margin: 3px; padding-left: 5px; width: 20px; background-color: lightblue; text-align: center;" class="fa fa-angle-double-right"></i>
                          </template> -->
                          <template v-if="product_item.product.data && product_item.product.data['0'] && product_item.product.data['0']['1'] && product_item.product.data['0']['1']['lang'] && product_item.product.data['0']['1']['lang'][StoreLang]">
                            <small> {{ product_item.product.data['0']['1']['lang'][StoreLang] }}</small>
                          </template>
                          <template v-else-if="product_item.product.data && product_item.product.data['0'] && product_item.product.data['0']['1'] && product_item.product.data['0']['1']['val']">
                            <small> {{ product_item.product.data['0']['1']['val'] }}</small>
                          </template>
                        </b-col>
                        <b-col sm="12" lg="3">
                          <template v-if="d_bundleData.data['0']['13'] && d_bundleData.data['0']['13']['val']['value'] === '1'">
                            {{ f_calculateProductTotalPrice([product_item]) }}
                          </template>
                        </b-col>
                      </b-row>
                    </template>
                </template>
                <template v-if="d_bundleData.data['0']['2'] && d_bundleData.data['0']['2'].list && d_bundleData.data['0']['2'].list.length > 0">
                  <b-row style="text-align: left; border-radius: 10px 10px; background-color: rgb(0 255 206); margin: 3px; padding: 3px;">
                    <b-col cols="12">
                      <i class="fa fa-angle-right"></i>
                      <strong> {{ StoreLangTranslation.data['highlights'][StoreLang] }}</strong>
                    </b-col>
                  </b-row>
                  <template v-for="(feature_item, feature_item_index) in d_bundleData.data['0']['2']['list']">
                    <template v-if="feature_item['11'] && feature_item['11'].val && feature_item['11'].val.value && ['1'].indexOf(feature_item['11'].val.value) !== -1">
                      <b-row style="margin: 5px; padding: 5px;">
                        <b-col sm="12" lg="12">
                          <i class="fa fa-angle-double-right" style="color: rgb(0 116 94);"></i>
                          <small> {{ feature_item['3']['lang'][StoreLang] }} </small>
                        </b-col>
                      </b-row>
                    </template>
                  </template>
                </template>
                <template v-if="d_bundleData.data['0']['2'] && d_bundleData.data['0']['2'].list && d_bundleData.data['0']['2'].list.length > 0">
                  <b-row style="text-align: left; border-radius: 10px 10px; background-color: rgb(0 255 206); margin: 3px; padding: 3px;">
                    <b-col cols="12">
                      <i class="fa fa-angle-right"></i>
                      <strong> {{ StoreLangTranslation.data['general_features'][StoreLang] }}</strong>
                    </b-col>
                  </b-row>
                  <template v-for="(feature_item, feature_item_index) in d_bundleData.data['0']['2']['list']">
                    <template v-if="feature_item['11'] && feature_item['11'].val && feature_item['11'].val.value && ['0', '3'].indexOf(feature_item['11'].val.value) !== -1">
                      <b-row style="margin: 5px; padding: 3px;">
                        <b-col sm="12" lg="12">
                          <i class="fa fa-angle-double-right" style="color: rgb(0 116 94);"></i>
                          <small v-if="['0'].indexOf(feature_item['11'].val.value) !== -1"> {{ feature_item['3']['lang'][StoreLang] }} </small>
                          <small v-if="['3'].indexOf(feature_item['11'].val.value) !== -1"> <a href="https://docs.wisdomera.io"> {{ feature_item['3']['lang'][StoreLang] }}</a> </small>
                        </b-col>
                      </b-row>
                    </template>
                  </template>
                </template>
                <template v-if="d_bundleData.data['0']['2'] && d_bundleData.data['0']['2'].list && d_bundleData.data['0']['2'].list.length > 0">
                  <b-row style="text-align: left; border-radius: 10px 10px; background-color: rgb(0 255 206); margin: 3px; padding: 3px;">
                    <b-col cols="12">
                      <i class="fa fa-angle-right"></i>
                      <strong> {{ StoreLangTranslation.data['customer_experience'][StoreLang] }}</strong>
                    </b-col>
                  </b-row>
                  <template v-for="(feature_item, feature_item_index) in d_bundleData.data['0']['2']['list']">
                    <template v-if="feature_item['11'] && feature_item['11'].val && feature_item['11'].val.value && ['2'].indexOf(feature_item['11'].val.value) !== -1">
                      <b-row style="margin: 5px; padding: 3px;">
                        <b-col sm="12" lg="12">
                          <i class="fa fa-angle-double-right" style="color: rgb(0 116 94);"></i>
                          <small> {{ feature_item['3']['lang'][StoreLang] }} </small>
                        </b-col>
                      </b-row>
                    </template>
                  </template>
                </template>
                <template v-if="d_bundleData.data['0']['2'] && d_bundleData.data['0']['2'].list && d_bundleData.data['0']['2'].list.length > 0">
                  <b-row style="text-align: left; border-radius: 10px 10px; background-color: rgb(0 255 206); margin: 3px; padding: 3px;">
                    <b-col cols="12">
                      <i class="fa fa-angle-right"></i>
                      <strong> {{ StoreLangTranslation.data['who_can_use'][StoreLang] }}</strong>
                    </b-col>
                  </b-row>
                  <template v-for="(feature_item, feature_item_index) in d_bundleData.data['0']['2']['list']">
                    <template v-if="feature_item['11'] && feature_item['11'].val && feature_item['11'].val.value && ['4'].indexOf(feature_item['11'].val.value) !== -1">
                      <b-row style="margin: 5px; padding: 3px;">
                        <b-col sm="12" lg="12">
                          <i class="fa fa-angle-double-right" style="color: rgb(0 116 94);"></i>
                          <small> {{ feature_item['3']['lang'][StoreLang] }} </small>
                        </b-col>
                      </b-row>
                    </template>
                  </template>
                </template>
                <b-row style="text-align: right; border-radius: 10px 10px; background-color: rgb(0 255 206); margin: 3px; padding: 3px;">
                  <b-col cols="12">
                    <i class="fa fa-angle-right"></i>
                    <span style="cursor: pointer; color: royalblue;" @click="f_agrementView()">
                      {{ StoreLangTranslation.data['click_for_agreement_preview'][StoreLang] }}
                    </span>
                  </b-col>
                </b-row>
              </b-col>
              <b-col sm="12" lg="4">
                <hr>
                <b-row style="margin: 3px;">
                  <b-col sm="12" lg="12">
                    <template v-if="d_bundleData.data['0']['12'] && d_bundleData.data['0']['12']['val'] && d_bundleData.data['0']['12']['val']['value'] === '1'">
                      <i class="fa fa-shopping-basket"></i>
                      {{ StoreLangTranslation.data['available_soon'][StoreLang] }}
                    </template>
                    <template v-else>
                      <template v-if="d_addToShopcartLoading">
                        <b-spinner variant="primary" label="Spinning"></b-spinner>
                      </template>
                      <template v-else>
                        <b-button v-if="f_addToShopcartEligible()" variant="white" @click="f_userShopCartBundleAdd()" size="sm">
                          <i class="fa fa-shopping-basket"></i>
                          {{ StoreLangTranslation.data['add_to_shopping_cart'][StoreLang] }}
                        </b-button>
                      </template>
                    </template>
                  </b-col>
                </b-row>
                <hr>
                <b-row style="margin: 3px;">
                  <b-col sm="12" lg="12">
                    <b-button variant="white" @click="f_supportModal()" size="sm">
                      <i class="fa fa-user-circle"></i>
                      {{ StoreLangTranslation.data['product_representative'][StoreLang] }}
                    </b-button>
<!--                     <b-button variant="white" @click="f_userShopCartBundleAdd()" size="sm">
                      <i class="fa fa-shopping-basket"></i>
                      {{ StoreLangTranslation.data['add_to_shopping_cart'][StoreLang] }}
                    </b-button>
 -->
                  </b-col>
                </b-row>
                <hr>
              </b-col>
            </b-row>
          </template>
      </b-col>
      <b-col sm="12" lg="2"></b-col>
    </b-row>
    <b-modal v-if="d_supportData.show" id="d_supportData_show" v-model="d_supportData.show" scrollable hide-footer :title="StoreLangTranslation.data['support_screen'][StoreLang]" centered header-bg-variant="info" header-text-variant="dark" size="lg">
      <p>
        <b-row>
          <b-col sm="12" lg="12">
            <support-screen :p_supportData="d_supportData"></support-screen>
          </b-col>
        </b-row>
      </p>
      <p>
      <b-row>
          <b-col sm="12" lg="6"></b-col>
          <b-col sm="12" lg="6" style="text-align: right;">
          </b-col>
        </b-row>
      </p>
    </b-modal>
    <b-modal v-if="d_selectedProductData.show" id="d_selectedProductData_show" v-model="d_selectedProductData.show" scrollable hide-footer :title="StoreLangTranslation.data['product_ingredients'][StoreLang]" centered header-bg-variant="info" header-text-variant="dark" size="lg">
      <p>
        <b-row>
          <b-col sm="12" lg="12">
            <shopcard-bundle-product-show :p_productData="d_selectedProductData.data"></shopcard-bundle-product-show>
          </b-col>
        </b-row>
      </p>
      <p>
      <b-row>
          <b-col sm="12" lg="6"></b-col>
          <b-col sm="12" lg="6" style="text-align: right;">
          </b-col>
        </b-row>
      </p>
    </b-modal>
    <b-modal v-if="d_agreementModal.show" id="d_agreementModal_show" v-model="d_agreementModal.show" scrollable hide-footer :title="StoreLangTranslation.data['agreement_preview'][StoreLang]" centered header-bg-variant="info" header-text-variant="dark" size="xl">
      <p>
        <b-row>
          <b-col sm="12" lg="12">
            <agreement-view :p_shopData="d_agreementModal.shop_data" :p_bundleData="d_agreementModal.bundle_data" :p_showMode="{'mode': 'approve'}"></agreement-view>
          </b-col>
        </b-row>
      </p>
    </b-modal>
  </div>
</template>

<script>
import { ClsWdm } from '@/wam/modules/wdm';
import store from '@/store';
import { ServiceFunctions } from '@/wam/modules/service';
import {
  default as PageInfo
} from '@/components/widgets/PageInfo';
import {
  default as SupportScreen
} from '@/wam/components/widgets/SupportScreen';
import {
  default as ShopcardBundleProductShow
} from '@/components/widgets/ShopcardBundleProductShow';
import {
  default as AgreementView
} from '@/wam/components/widgets/AgreementView';

import { mapGetters } from 'vuex';
export default {
  name: 'product_details',
  computed: mapGetters({
    StoreDevice: 'StoreDevice',
    StoreLang: 'StoreLang',
    StoreLangList: 'StoreLangList',
    StoreLangTranslation: 'StoreLangTranslation',
    StoreFrontendSettings: 'StoreFrontendSettings',
    StoreShopCartList: 'StoreShopCartList',
    StoreCurrencyType: 'StoreCurrencyType',
  }),
  components: {
    PageInfo,
    SupportScreen,
    ShopcardBundleProductShow,
    AgreementView,
  },
  props: {},
  data () {
    return {
      d_agreementModal: {
        'show': false,
        'shop_data': {},
        'bundle_data': {},
      },
      d_selectedProductData: {
        'data': '',
        'show': false
      },
      d_bundleProductList: [],
      d_loading: false,
      d_addToShopcartLoading: false,
      d_supportData: {
        'maximum_characters_name': 100,
        'maximum_characters_note': 500,
        'mail_status': true,
        'phone_status': true,
        'phone': '',
        'mail': '',
        'show': false,
        'edit_eligible': false,
        'operation_name': '',
        'operation_note': '',
        'operation_note_additional': '',
        'maximum_characters': 500,
        'subject': 'ürün destek'
      },
      d_bundleData: '',
      d_user: '',
      d_hoverIndex: '',
      d_searchText: '',
      d_userShopClientID: '',
      d_userShopBundleClientID: '',
      d_frontendList: [],
      d_usrShpList: [],
    };
  },
  created: function () {
    this.d_user = JSON.parse(localStorage.getItem('user'));
  },
  beforeMount () {},
  mounted: function () {
    if (this.$route.query.user_shop_client_id) {
      this.d_userShopClientID = this.$route.query.user_shop_client_id;
    }
    if (this.$route.query.user_shop_bundle_client_id) {
      this.d_userShopBundleClientID = this.$route.query.user_shop_bundle_client_id;
    }
    this.f_getBundleDetails();
  },
  methods: {
    f_addToShopcartEligible: function () {
      // normalde default olarak bundle sepete eklenebilir şekilde çalışıyor. Ama can_customer_add_to_shopcart ekleyemez şeklinde tanımlandıysa buton gösterilmiyor.
      let view_eligible = true;
      try {
        let can_customer_add_to_shopcart = this.d_bundleData['data']['0']['21']['val']['value'];
        if (can_customer_add_to_shopcart === '1') {
          view_eligible = false;
        }
      } catch(err) {}
      return view_eligible;
    },
    f_agrementView: function () {
      this.d_agreementModal.shop_data = {'client_id': this.d_userShopClientID };
      this.d_agreementModal.bundle_data = {'client_id': this.d_userShopBundleClientID, 'agreement_client_id': '' };
      this.d_agreementModal.show = true;
    },
    f_openProductIngredients: function (product_data) {
      this.d_selectedProductData.show = true;
      this.d_selectedProductData.data = product_data;
    },
    f_getOptionLabel: function (option_value, options) {
      // console.log('option_value : ', option_value);
      // console.log('options : ', options);
      let option_label = '';
      for (let i in options) {
        if (options[i].value === option_value) {
          option_label = options[i].translation[this.StoreLang];
          break;
        }
      }
      return option_label;
    },
    f_calculateProductTotalPrice: function (bundle_products) {
      let currency_text = this.StoreCurrencyType.label;
      let total_amount = 0;
      for (let product_index in bundle_products) {
        try {
          for (let currency_amount_index in bundle_products[product_index].product.data['0']['25'].list) {
            let currency_amount_item = bundle_products[product_index].product.data['0']['25'].list[currency_amount_index];
            try {
              if (currency_amount_item['26']['val']['value'] === this.StoreCurrencyType.value) {
                total_amount += parseFloat(currency_amount_item['27']['val']);
              }
            } catch(err) {}
          }
        } catch(err) {}
      }
      return total_amount + ' ' + currency_text;
    },
    f_showCheckProduct: function (product_data) {
      let show_product = true;
      try {
        if (product_data.data['0']['48']['val']['value'] === '0') {
          show_product = false;
        }
      } catch(err) {}
      try {
        if (product_data.data['0']['0']['val']['value'] !== '1') {
          show_product = false;
        }
      } catch(err) {}
      return show_product;
    },
    f_supportModal: function () {
      if (this.d_user) {
        this.d_supportData.show = true;
        this.d_supportData.operation_name = this.d_bundleData.data['0']['1']['lang'][this.StoreLang];
        this.d_supportData.operation_note = this.StoreLangTranslation.data['i_want_to_get_information_about_bundle_by_online_meeting'][this.StoreLang];
        this.d_supportData.user_shop_bundle_client_id = this.d_userShopBundleClientID;
      } else {
        this.f_login();
      }
    },
    f_getBundleDetails: function () {
      this.d_loading = true;
      let query = 'user_shop_client_id=' + this.d_userShopClientID;
      query += '&user_shop_bundle_client_id=' + this.d_userShopBundleClientID;
      let data = {};
      ServiceFunctions.bundle_details(query, data)
        .then(resp => {
          this.d_loading = false;
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            if (resp.data.data) {
              this.d_bundleData = resp.data.data;
              this.d_bundleProductList = resp.data.product_list;
            } else {
              alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
            }
          } else {
            alert(resp.data.status_code + ' = ' + resp.data.status_message);
          }
        });
    },
    f_login: function () {
      this.$router.push({ 'path': '/login', 'query': {} });
    },
    f_userShopCartBundleAdd: function () {
      if (this.d_user && this.d_user.username) {
        this.d_addToShopcartLoading = true;
        let query = 'user_client_id=' + this.d_user.username;
        query += '&add_bundle_count=1';
        query += '&user_shop_client_id=' + this.d_userShopClientID;
        let data = {};
        if (this.d_userShopBundleClientID) {
          query += '&user_shop_bundle_client_id=' + this.d_userShopBundleClientID;
        }
        ServiceFunctions.user_shopcart_bundle_add(query, data)
          .then(resp => {
            this.d_addToShopcartLoading = false;
            if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
              alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
              if (this.StoreShopCartList.status === 1) {
                this.$store.commit('MutationShopCartList', { 'status': 0, 'data': this.StoreShopCartList.data });
              } else {
                this.$store.commit('MutationShopCartList', { 'status': 1, 'data': this.StoreShopCartList.data });
              }
            } else {
              alert(resp.data.status_code + ' = ' + resp.data.status_message);
            }
          });
      } else {
        this.f_login();
      }
    },
  },
  watch: {}
};

</script>

